import React from 'react'
import { useNavigate } from 'react-router-dom' // React Router's hook for navigation
import { themeColors } from '../theme'

export default function WelcomeScreen() {
    const navigate = useNavigate() // Use navigate hook for navigation

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                height: '100vh',
                backgroundColor: themeColors.redcolor,
                padding: '16px',
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                    src={require('../assets/images/toontales_icon_transparent.png')}
                    alt="Toon Tales Icon"
                    style={{ width: '350px', height: '350px' }}
                    onClick={() => navigate('/login')}
                />
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                }}
            >
                <button
                    onClick={() => navigate('/signup')}
                    style={{
                        padding: '12px',
                        borderRadius: '12px',
                        backgroundColor: themeColors.whiteColor,
                        color: themeColors.redcolor,
                        fontSize: '20px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        margin: '0 28px',
                    }}
                >
                    Sign Up
                </button>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <p
                        style={{
                            color: themeColors.whiteColor,
                            fontWeight: '600',
                        }}
                    >
                        Already have an account?
                    </p>
                    <button
                        onClick={() => navigate('/login')}
                        style={{
                            color: themeColors.blueColor,
                            fontWeight: '600',
                            backgroundColor: 'transparent',
                            border: 'none',
                            cursor: 'pointer',
                            marginLeft: '8px',
                        }}
                    >
                        Log In
                    </button>
                </div>
            </div>
        </div>
    )
}
