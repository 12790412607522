import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom' // React Router's hook for navigation
import { themeColors } from '../theme'
import { loginUser } from '../actions/AuthActions'
import { ArrowLeftIcon } from '@heroicons/react/solid' // Assuming you're using the heroicons package

export default function LoginScreen() {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const onLoginPressed = async () => {
        const userData = {
            email: email,
            password: password,
        }
        console.log('calling login user')
        const res = await loginUser(userData)
        // Handle login response here
    }

    return (
        <div
            style={{
                backgroundColor: themeColors.redcolor,
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            {/* SafeAreaView equivalent in React.js */}
            <div style={{ padding: '16px' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <button
                        onClick={() => navigate(-1)} // goBack equivalent
                        style={{
                            padding: '8px',
                            borderRadius: '16px 0 0 16px',
                            backgroundColor: themeColors.redcolor,
                        }}
                    >
                        <ArrowLeftIcon
                            style={{
                                width: '20px',
                                height: '20px',
                                color: 'white',
                            }}
                        />
                    </button>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                        src={require('../assets/images/toontales_icon_transparent.png')}
                        alt="Toon Tales Icon"
                        style={{ width: '200px', height: '200px' }}
                    />
                </div>
            </div>

            <div
                style={{
                    flex: 1,
                    backgroundColor: 'white',
                    padding: '32px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    marginTop: '16px',
                }}
            >
                {/* Form section */}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                    }}
                >
                    <label style={{ marginLeft: '16px', color: '#4a5568' }}>
                        Email Address
                    </label>
                    <input
                        type="email"
                        placeholder="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={{
                            padding: '16px',
                            backgroundColor: '#f7fafc',
                            color: '#4a5568',
                            borderRadius: '16px',
                            border: 'none',
                            marginBottom: '12px',
                        }}
                    />
                    <label style={{ marginLeft: '16px', color: '#4a5568' }}>
                        Password
                    </label>
                    <input
                        type="password"
                        placeholder="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        style={{
                            padding: '16px',
                            backgroundColor: '#f7fafc',
                            color: '#4a5568',
                            borderRadius: '16px',
                            border: 'none',
                        }}
                    />
                    <button
                        style={{
                            alignSelf: 'flex-end',
                            marginTop: '8px',
                            color: '#4a5568',
                            border: 'none',
                            background: 'transparent',
                        }}
                        onClick={() => navigate('/forgot-password')}
                    >
                        Forgot Password?
                    </button>

                    <button
                        onClick={onLoginPressed}
                        style={{
                            padding: '16px',
                            borderRadius: '16px',
                            backgroundColor: themeColors.redcolor,
                            color: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                        }}
                    >
                        Login
                    </button>
                </div>

                {/* Links for sign up and reset password */}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '28px',
                    }}
                >
                    <p style={{ color: '#718096', fontWeight: '600' }}>
                        Don't have an account?
                    </p>
                    <button
                        onClick={() => navigate('/signup')}
                        style={{
                            color: themeColors.blueColor,
                            fontWeight: '600',
                            backgroundColor: 'transparent',
                            border: 'none',
                            cursor: 'pointer',
                            marginLeft: '8px',
                        }}
                    >
                        Sign Up
                    </button>
                </div>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '28px',
                    }}
                >
                    <p style={{ color: '#718096', fontWeight: '600' }}>
                        Forgot password?
                    </p>
                    <button
                        onClick={() => navigate('/reset-password')}
                        style={{
                            color: themeColors.blueColor,
                            fontWeight: '600',
                            backgroundColor: 'transparent',
                            border: 'none',
                            cursor: 'pointer',
                            marginLeft: '8px',
                        }}
                    >
                        Reset Password
                    </button>
                </div>
            </div>
        </div>
    )
}
