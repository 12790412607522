import React, { useEffect, useState } from 'react'
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
    logoutUser,
    setCurrentUser,
    sendExpoPushToken,
} from '../actions/AuthActions'
import { SET_AVATAR } from '../actions/types'
import WelcomeScreen from '../screens/WelcomeScreen'
import LoginScreen from '../screens/LoginScreen'
import SignUpScreen from '../screens/SignUpScreen'
import ResetPasswordScreen from '../screens/ResetPasswordScreen'
import VerificationCodeScreen from '../screens/VerificationCodeScreen'
import TabNavigation from './TabNavigation'

export default function AppNavigation() {
    const { isAuthenticated } = useSelector((state) => state.auth)
    const [expoPushToken, setExpoPushToken] = useState('')
    const dispatch = useDispatch()

    // Auto-login logic on app mount (runs only once)
    useEffect(() => {
        const autoLoginLogout = async () => {
            const getUser = localStorage.getItem('user')
            if (getUser) {
                const user = JSON.parse(getUser)
                if (user) {
                    if (user.expiry - Date.now() < 0) {
                        logoutUser()
                    } else {
                        dispatch(setCurrentUser(user.user_info))
                    }
                }
            }
        }

        autoLoginLogout()

        const setSelectedAvatar = async () => {
            const selectedAvatarValue = localStorage.getItem('selectedAvatar')
            if (selectedAvatarValue) {
                const selectedAvatar = JSON.parse(selectedAvatarValue)
                dispatch({ type: SET_AVATAR, payload: selectedAvatar })
            }
        }
        setSelectedAvatar()
    }, [dispatch])

    // Send the push token to the server when user becomes authenticated
    useEffect(() => {
        if (isAuthenticated) {
            const storedToken = localStorage.getItem('expoPushToken')
            if (storedToken) {
                sendPushTokenToServer(storedToken)
                localStorage.removeItem('expoPushToken') // Clear once sent
            }
        }
    }, [isAuthenticated]) // Runs every time `isAuthenticated` changes

    const handlePushToken = (event) => {
        const message = event.data

        if (message?.expoPushToken && message?.expoPushToken !== '') {
            setExpoPushToken(message.expoPushToken) // Save the token in state (optional)

            // Save the token in localStorage
            localStorage.setItem('expoPushToken', message.expoPushToken)

            // If the user is authenticated, send the token to the server immediately
            if (isAuthenticated) {
                sendPushTokenToServer(message.expoPushToken)
                localStorage.removeItem('expoPushToken') // Clear it once sent
            }
        }
    }

    // Add event listener for receiving push token from the React Native side
    useEffect(() => {
        window.addEventListener('message', handlePushToken)

        return () => {
            window.removeEventListener('message', handlePushToken)
        }
    }, [isAuthenticated]) // Add `isAuthenticated` to the dependency array to ensure we recheck when it changes

    // Function to send the push token to the server
    const sendPushTokenToServer = async (token) => {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user) {
            try {
                const data = {
                    token: token,
                }
                const res = await sendExpoPushToken(data)
                console.log('Push token sent to server:', res)
            } catch (error) {
                console.error('Error sending push token to server:', error)
            }
        }
    }

    return (
        <Router>
            <Routes>
                {isAuthenticated ? (
                    <Route path="*" element={<TabNavigation />} />
                ) : (
                    <>
                        <Route path="/" element={<WelcomeScreen />} />
                        <Route path="/login" element={<LoginScreen />} />
                        <Route path="/signup" element={<SignUpScreen />} />
                        <Route
                            path="/reset-password"
                            element={<ResetPasswordScreen />}
                        />
                        <Route
                            path="/verification-code"
                            element={<VerificationCodeScreen />}
                        />
                    </>
                )}
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </Router>
    )
}
