import React, { useState } from 'react'
import {
    Button,
    Card,
    CardContent,
    Typography,
    CircularProgress,
    Dialog,
} from '@mui/material'
import { CheckCircle, Lock, Star, Subscriptions } from '@mui/icons-material' // Material-UI Icons
import { themeColors } from '../theme'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
    cancelRazorpaySubscription,
    cancelStripeSubscription,
} from '../actions/PaymentActions'

export const UpgradeSubscriptionScreen = () => {
    const navigate = useNavigate()
    const { countryCode, is_subscription_active } = useSelector(
        (state) => state.auth
    )
    const [loading, setLoading] = useState(false) // Loading state for button
    const [isCancelling, setIsCancelling] = useState(false) // Modal loading state

    const currency = countryCode === 'IN' ? 'Rupee' : 'USD'
    const currencySymbol = countryCode === 'IN' ? '₹' : '$'

    const handleCancelSubscription = async () => {
        setLoading(true)
        setIsCancelling(true) // Show modal with loading animation
        try {
            let is_cancelled
            if (countryCode === 'IN') {
                is_cancelled = await cancelRazorpaySubscription({})
                if (is_cancelled) {
                    alert('Razorpay subscription cancelled successfully.')
                }
            } else {
                is_cancelled = await cancelStripeSubscription()
                if (is_cancelled) {
                    alert('Stripe subscription cancelled successfully.')
                }
            }
            navigate('/settings')
        } catch (error) {
            console.error('Error managing subscription:', error)
            alert('Error: Failed to manage subscription.')
        } finally {
            setLoading(false)
            setIsCancelling(false) // Hide the modal after processing
        }
    }

    // Render active subscription view
    if (is_subscription_active) {
        return (
            <div style={styles.container}>
                <div style={styles.subscriptionActiveContainer}>
                    <Typography style={styles.activeText}>
                        You are currently subscribed.
                    </Typography>
                    <Button
                        variant="contained"
                        style={styles.cancelButton}
                        onClick={handleCancelSubscription}
                        disabled={loading}
                    >
                        {loading ? (
                            <CircularProgress size={24} color="inherit" />
                        ) : countryCode === 'IN' ? (
                            'Cancel Razorpay Subscription'
                        ) : (
                            'Manage Stripe Subscription'
                        )}
                    </Button>
                </div>

                {/* Modal for processing cancellation */}
                <Dialog open={isCancelling} onClose={() => {}}>
                    <div style={styles.dialogContent}>
                        <CircularProgress />
                        <Typography variant="h6" style={{ marginTop: '20px' }}>
                            Please wait, canceling subscription, do not leave
                            the screen
                        </Typography>
                    </div>
                </Dialog>
            </div>
        )
    }

    // Render subscription options if no active subscription
    return (
        <div style={styles.container}>
            <Typography variant="h4" style={styles.header}>
                Unlock Premium Features 🚀🔥
            </Typography>

            <div style={styles.featuresContainer}>
                <Typography style={styles.feature}>
                    <CheckCircle style={styles.icon} /> Unlimited messages
                </Typography>
                <Typography style={styles.feature}>
                    <CheckCircle style={styles.icon} /> Access exclusive content
                </Typography>
                <Typography style={styles.feature}>
                    <Star style={styles.icon} /> Priority support ⭐
                </Typography>
                <Typography style={styles.feature}>
                    <Lock style={styles.icon} /> Unlock all features
                </Typography>
                <Typography style={styles.feature}>
                    <Subscriptions style={styles.icon} /> Monthly updates
                </Typography>
            </div>

            {countryCode !== 'IN' && (
                <Card style={styles.pricingCardStyle}>
                    <CardContent>
                        <Typography variant="h5" color="primary">
                            Subscribe Now
                        </Typography>
                        <Typography variant="h4">{`${currencySymbol}10/month`}</Typography>
                        <Typography>
                            Get all these amazing features and more!
                        </Typography>
                        <Typography>
                            Join our premium community now 🚀
                        </Typography>
                        <Button
                            variant="contained"
                            style={styles.subscribeButton}
                            onClick={() => navigate('/stripe-subscription')}
                        >
                            Subscribe Now
                        </Button>
                    </CardContent>
                </Card>
            )}

            {countryCode === 'IN' && (
                <Card style={styles.pricingCardStyle}>
                    <CardContent>
                        <Typography variant="h5" color="primary">
                            Subscribe Now
                        </Typography>
                        <Typography variant="h4">{`${currencySymbol}249/month`}</Typography>
                        <Typography>
                            Unlock exclusive access to premium content!
                        </Typography>
                        <Typography>
                            Special offer for a limited time ⭐
                        </Typography>
                        <Button
                            variant="contained"
                            style={styles.subscribeButton}
                            onClick={() => navigate('/razorpay-subscription')}
                        >
                            Subscribe Now
                        </Button>
                    </CardContent>
                </Card>
            )}
        </div>
    )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: themeColors.greyColor,
        padding: '20px',
    },
    header: {
        marginBottom: '30px',
        fontWeight: 'bold',
        color: themeColors.blueColor,
        textAlign: 'center',
    },
    featuresContainer: {
        marginBottom: '20px',
        textAlign: 'center',
    },
    feature: {
        fontSize: '18px',
        marginBottom: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    icon: {
        marginRight: '8px',
        color: themeColors.blueColor,
    },
    pricingCardStyle: {
        width: '100%',
        //margin: '20px 0',
        borderRadius: '20px',
        //padding: '20px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    subscribeButton: {
        marginTop: '20px',
        backgroundColor: themeColors.blueColor,
        color: '#fff',
        padding: '10px 20px',
        borderRadius: '20px',
        fontSize: '16px',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '30px',
        textAlign: 'center',
    },
}
