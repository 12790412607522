import React, { useState, useEffect, useRef } from 'react'
import { MessageList, Input, Avatar } from 'react-chat-elements'
import SpeechRecognition, {
    useSpeechRecognition,
} from 'react-speech-recognition'
import Lottie from 'react-lottie-player'
import voiceWaveAnimation from '../assets/lottie-animations/voice-wave.json'
import loadingAnimation from '../assets/lottie-animations/loading.json'
import 'react-chat-elements/dist/main.css'
import { useSelector } from 'react-redux'
import { chatWithOpenAI, getPreviousMessages } from '../actions/ChatActions'
import CircularAudioWave from '../libs/circular-audio-wave/src/js/circular-audio-wave'
import {
    Send as SendIcon,
    Mic as MicIcon,
    PlayArrow as PlayIcon,
    ArrowForward as ContinueIcon, // Continue icon
} from '@mui/icons-material' // Material-UI Icons
import { IconButton, TextField } from '@mui/material' // MUI components

const background_image = require('../assets/images/chat_background.jpg')

export const ChatScreen = () => {
    const [messages, setMessages] = useState([])
    const [isTyping, setIsTyping] = useState(false)
    const [currentMessage, setCurrentMessage] = useState('')
    const [isRecording, setIsRecording] = useState(false)
    const [showAnimation, setShowAnimation] = useState(false)
    const [audioId, setAudioId] = useState(null)
    const [audioIdToPlay, setAudioIdToPlay] = useState(null)
    const [isLoading, setIsLoading] = useState(true) // New state for loading
    const waveRef = useRef(null) // Ref to handle the audio wave
    const messageEndRef = useRef(null) // Ref to scroll to the bottom of the messages

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
    } = useSpeechRecognition()
    const { selectedAvatar, isAvatarIconClicked } = useSelector(
        (state) => state.auth
    )

    useEffect(() => {
        const fetchMessages = async () => {
            setIsLoading(true) // Start loading animation
            const fetchedMessages = await getPreviousMessages(
                selectedAvatar.name,
                selectedAvatar.isCustom
            )
            const messagesWithAvatar = fetchedMessages.map((message) => ({
                ...message,
                position: message.role === 'user' ? 'right' : 'left',
                avatar: message.role !== 'user' ? selectedAvatar.url : null,
            }))
            setMessages(messagesWithAvatar)
            setIsLoading(false) // End loading animation

            // Play the last avatar message by default if available
            const lastAvatarMessage =
                messagesWithAvatar[messagesWithAvatar.length - 1]
            if (lastAvatarMessage?.audio) {
                playAvatarMessage(lastAvatarMessage.audio)
            }
        }
        fetchMessages()

        // Cleanup function to stop the waveform audio when leaving the screen
        return () => {
            if (waveRef.current?.playing) {
                stopAudio()
            }
        }
    }, [selectedAvatar])

    useEffect(() => {
        // Scroll to the bottom of the message list whenever messages change
        scrollToBottom()
    }, [messages])

    const scrollToBottom = () => {
        messageEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }

    useEffect(() => {
        if (!listening && transcript) {
            stopRecording()
            setCurrentMessage(transcript)
            onSendMessage(transcript)
        }
    }, [listening, transcript])

    const startRecording = () => {
        resetTranscript()
        setIsRecording(true)
        setShowAnimation(true)
        SpeechRecognition.startListening({ continuous: false })
    }

    const stopRecording = () => {
        setIsRecording(false)
        setShowAnimation(false)
        SpeechRecognition.stopListening()
    }

    const onSendMessage = async (messageToSend) => {
        if (!messageToSend.trim()) return

        const newMessage = {
            position: 'right',
            type: 'text',
            text: messageToSend,
            date: new Date(),
        }
        setMessages([...messages, newMessage])
        setCurrentMessage('')

        const messageData = {
            message: messageToSend,
            avatarName: selectedAvatar.name,
            isCustom: selectedAvatar.isCustom,
        }

        setIsTyping(true) // Set typing state to true
        const chatResponse = await chatWithOpenAI(messageData)
        setIsTyping(false) // Set typing state to false once response is received

        if (chatResponse?.code === 'INSUFFICIENT_CREDITS') {
            alert('Limit reached. Please upgrade your subscription.')
            return
        }

        const { messages: messagesReceived } = chatResponse
        const botMessage = {
            position: 'left',
            type: 'text',
            text:
                messagesReceived[messagesReceived.length - 1]?.text ||
                'No response',
            date: new Date(),
            avatar: selectedAvatar.url,
            audio: messagesReceived[messagesReceived.length - 1]?.audio, // Assuming audio is in response
        }

        setMessages((prevMessages) => [...prevMessages, botMessage])

        // Automatically play the avatar's reply if audio is present
        if (botMessage.audio) {
            playAvatarMessage(botMessage.audio)
        }
    }

    const playAvatarMessage = (audioUrl) => {
        const match = audioUrl.match(/audio_([a-zA-Z0-9-]+)\.mp3$/)
        const audioId = match ? match[1] : null

        if (audioId === audioIdToPlay && waveRef.current?.playing) {
            stopAudio()
            return
        }

        if (waveRef.current?.playing) {
            stopAudio()
        }

        setAudioId(audioId)
        loadAudioWaveform(audioId)
    }

    const onPlayVoiceMessage = (msg) => {
        if (!msg?.audio) return
        playAvatarMessage(msg.audio)
    }

    const stopAudio = () => {
        if (waveRef.current) {
            waveRef.current.pause()
            waveRef.current.reset()
            waveRef.current.playing = false
            setAudioId(null)
        }
    }

    const handleWaveformClick = () => {
        if (waveRef.current?.playing) {
            stopAudio()
        } else {
            loadAudioWaveform(audioIdToPlay)
        }
    }

    const loadAudioWaveform = (audioIdForWaveform) => {
        try {
            setAudioIdToPlay(audioIdForWaveform)
            waveRef.current = new CircularAudioWave(
                document.getElementById('chart-container')
            )

            const audioUrl = `https://toontales.leadingbuddy.com/public/audio/audio_${audioIdForWaveform}.mp3`
            waveRef.current.loadAudio(audioUrl).then(() => {
                waveRef.current.play()
                waveRef.current.playing = true
            })
        } catch (error) {
            console.error('Error loading audio waveform:', error)
        }
    }

    // Continue action function
    const onContinue = () => {
        onSendMessage('continue')
    }

    if (!browserSupportsSpeechRecognition) {
        return <span>Your browser does not support speech recognition.</span>
    }

    return (
        <div
            style={{
                padding: '20px',
                backgroundImage: `url(${background_image})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'repeat',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
            }}
        >
            {/* Loading Animation */}
            {isLoading && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <Lottie
                        loop
                        animationData={loadingAnimation}
                        play
                        style={{ width: 400, height: 400 }}
                    />
                </div>
            )}

            {/* Avatar and Description */}
            {!isLoading && isAvatarIconClicked && (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '10px',
                    }}
                >
                    <Avatar
                        src={selectedAvatar.url}
                        alt={selectedAvatar.name}
                        size="large"
                        style={{ marginRight: '10px' }}
                    />
                    <p>{selectedAvatar.description}</p>
                </div>
            )}

            {/* Message List */}
            <div
                style={{
                    flex: 1,
                    overflowY: 'auto',
                    paddingBottom: '170px', // Extra padding for input and nav bar
                }}
            >
                <MessageList
                    className="message-list"
                    lockable={true}
                    toBottomHeight="100%"
                    onClick={(msg) => onPlayVoiceMessage(msg)}
                    dataSource={messages.map((msg) => ({
                        position: msg.position || 'left',
                        type: msg.type || 'text',
                        text: (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {msg.text}
                                {msg.audio && (
                                    <IconButton
                                        onClick={() => onPlayVoiceMessage(msg)}
                                        style={{
                                            background:
                                                'linear-gradient(45deg, violet, darkblue)',
                                            borderRadius: '50%',
                                            color: 'white',
                                            padding: '10px',
                                            animation:
                                                'shinyButton 3s infinite',
                                            marginLeft: '8px',
                                        }}
                                    >
                                        <PlayIcon
                                            style={{ fontSize: '30px' }}
                                        />
                                    </IconButton>
                                )}
                            </div>
                        ),
                        date: msg.createdAt || new Date(),
                        avatar: msg.avatar,
                        audio: msg?.audio,
                    }))}
                />
                <div ref={messageEndRef} /> {/* Scroll to bottom reference */}
            </div>

            {/* Typing Indicator */}
            {isTyping && (
                <div style={typingIndicatorContainer}>
                    <div style={typingDot} />
                    <div style={typingDot} />
                    <div style={typingDot} />
                </div>
            )}

            {/* Input field, Mic, Send icons, and Audio Waveform */}
            <div
                style={{
                    position: 'fixed',
                    bottom: '6%', // Adjusted to be just above the TabNavigation
                    left: 0,
                    width: '100%',
                    paddingTop: '10px',
                    backgroundColor: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    zIndex: 999, // Higher than the TabNavigation zIndex
                    flexDirection: 'column', // Stack input field and waveform vertically
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <IconButton onClick={startRecording} color="primary">
                        <MicIcon />
                    </IconButton>
                    <TextField
                        placeholder="Type a message..."
                        multiline
                        fullWidth
                        value={currentMessage}
                        onChange={(e) => setCurrentMessage(e.target.value)}
                        variant="outlined"
                        style={{ margin: '0 10px' }}
                    />
                    <IconButton
                        onClick={() => onSendMessage(currentMessage)}
                        color="primary"
                    >
                        <SendIcon />
                    </IconButton>
                    <IconButton onClick={onContinue} color="primary">
                        <ContinueIcon />
                    </IconButton>
                </div>

                {/* Audio Waveform Display */}
                <div
                    id="chart-container"
                    style={{
                        width: '100%',
                        height: '75px',
                        cursor: 'pointer',
                        //marginTop: '10px', // Place below the input field
                        marginBottom: '10px', // Place below the input field
                    }}
                    onClick={handleWaveformClick}
                ></div>
            </div>

            {/* Voice Recording Modal */}
            {isRecording && (
                <div style={modalStyle}>
                    <div style={modalContentStyle}>
                        <Lottie
                            loop
                            animationData={voiceWaveAnimation}
                            play
                            style={{ width: 200, height: 200 }}
                        />
                        <p>Recording...</p>
                        <button
                            onClick={stopRecording}
                            style={{
                                padding: '10px',
                                backgroundColor: 'red',
                                color: 'white',
                            }}
                        >
                            Stop
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

// Typing indicator styles
const typingIndicatorContainer = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '220px',
}

const typingDot = {
    width: '8px',
    height: '8px',
    backgroundColor: '#ccc',
    borderRadius: '50%',
    margin: '0 4px',
    animation: 'typingAnimation 1s infinite ease-in-out',
    animationDelay: '0s, 0.2s, 0.4s', // Delay between dots
}

// CSS Keyframes for typing animation
const typingKeyframes = `
@keyframes typingAnimation {
    0% { opacity: 0.2; }
    20% { opacity: 1; }
    100% { opacity: 0.2; }
}
`

// Define CSS keyframes for the shiny effect
const shinyKeyframes = `
@keyframes shinyButton {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}`

// Function to inject the keyframes into the document's head
const addShinyEffect = () => {
    const style = document.createElement('style')
    style.type = 'text/css'
    style.innerHTML = shinyKeyframes
    document.head.appendChild(style)
}

// Inject the shiny effect
addShinyEffect()

// Inject typing animation
const addKeyframes = () => {
    const style = document.createElement('style')
    style.type = 'text/css'
    style.innerHTML = typingKeyframes
    document.head.appendChild(style)
}

addKeyframes()

// Modal styles
const modalStyle = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}

const modalContentStyle = {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px',
    textAlign: 'center',
}
