import React, { useEffect, useState } from 'react'
import {
    Button,
    Avatar,
    Badge,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Typography,
    IconButton,
} from '@mui/material' // Using Material UI components
import { useNavigate, useLocation } from 'react-router-dom' // React Router for navigation
import { useDispatch, useSelector } from 'react-redux'
import AddCircleIcon from '@mui/icons-material/AddCircle' // Import specific icon from Material Icons
import ArrowRightIcon from '@mui/icons-material/ArrowRight' // Import specific icon from Material Icons
import { themeColors } from '../theme' // Import custom theme
import { SET_AVATAR } from '../actions/types'

export const SelectAvatarGridScreen = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const isCustomAvatars = location.state?.isCustomAvatars
    const { avatars, customAvatars } = useSelector((state) => state.auth)
    const [dataSource, setDataSource] = useState([])
    const [visible, setVisible] = useState(false)
    const [selectedAvatar, setSelectedAvatar] = useState({})

    useEffect(() => {
        const avatarsToMap =
            isCustomAvatars === 'true' ? customAvatars : avatars
        const avatarsToSet = avatarsToMap.map((avatar, index) => ({
            id: index,
            name: avatar.name,
            profession: avatar.profession,
            url: avatar.url,
            description: avatar.description,
            isCustom: avatar.isCustom,
        }))
        setDataSource(avatarsToSet)
    }, [isCustomAvatars, avatars, customAvatars])

    const setSelectedAvatarRedux = async (selectedAvatarValue) => {
        if (selectedAvatarValue) {
            const selectedAvatar = JSON.parse(selectedAvatarValue)
            dispatch({ type: SET_AVATAR, payload: selectedAvatar })
        }
    }

    const onAvatarSelected = async (value) => {
        // Dispatch avatar selection
        localStorage.setItem('selectedAvatar', JSON.stringify(value))
        setSelectedAvatarRedux(JSON.stringify(value))
        setVisible(false)
        navigate('/chat', { state: { showAnimation: 'false' } })
    }

    const handleAvatarClick = (item) => {
        setSelectedAvatar(item)
        setVisible(true)
    }

    if (dataSource.length === 0) {
        return (
            <div style={styles.container}>
                <div style={styles.listContainer}>
                    <Typography>
                        No Custom Avatars Found, Create Some!
                    </Typography>
                    <div
                        style={styles.button}
                        onClick={() => navigate('/create-avatar')}
                    >
                        <div style={styles.iconRow}>
                            <AddCircleIcon style={styles.createAvatar} />
                            <Typography style={styles.about}>
                                Create Avatar
                            </Typography>
                        </div>
                        <ArrowRightIcon style={styles.icon2} />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div style={styles.container}>
            <div style={styles.listContainer}>
                {/* Avatar Selection Modal */}
                <Dialog
                    open={visible}
                    onClose={() => setVisible(false)}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>{selectedAvatar.name}</DialogTitle>
                    <DialogContent>
                        <Avatar
                            src={selectedAvatar.url}
                            sx={{ width: 150, height: 150 }}
                        />
                        <Typography>{selectedAvatar.description}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => onAvatarSelected(selectedAvatar)}
                            style={{
                                backgroundColor: themeColors.redcolor,
                                color: 'white',
                            }}
                        >
                            Select {selectedAvatar.name}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Avatar Grid */}
                <Grid container spacing={2}>
                    {dataSource.map((item) => (
                        <Grid
                            item
                            xs={4}
                            key={item.id}
                            style={{ textAlign: 'center' }}
                        >
                            <IconButton onClick={() => handleAvatarClick(item)}>
                                <Avatar
                                    src={item.url}
                                    sx={{
                                        width: 80,
                                        height: 80,
                                        backgroundColor: themeColors.whiteColor,
                                        border: `2px solid ${themeColors.redcolor}`,
                                    }}
                                />
                                <Badge
                                    badgeContent=" "
                                    color="success"
                                    overlap="circular"
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                />
                            </IconButton>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    )
}

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '10%',
        marginBottom: '10%',
        //width: '100vw',
        //height: '100vh',
    },
    listContainer: {
        //display: 'flex',
        //alignItems: 'center',
        //position: 'center',
        width: '100vw',
    },
    button: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '20px',
        padding: '10px',
        backgroundColor: 'white',
        marginTop: '20px',
        cursor: 'pointer',
    },
    createAvatar: {
        color: themeColors.greenColor,
        fontSize: '40px',
    },
    about: {
        fontSize: '16px',
        marginLeft: '11px',
    },
    iconRow: {
        display: 'flex',
        alignItems: 'center',
    },
    icon2: {
        color: 'rgba(128,128,128,1)',
        fontSize: '40px',
    },
}
