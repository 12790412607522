import React, { useState } from 'react'
import {
    Button,
    Typography,
    Dialog,
    CircularProgress,
    TextField,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { themeColors } from '../theme'
import { RAZORYPAY_KEY_ID, SERVER_HOSTNAME } from '../utils/keys'
import {
    createRazorpaySubscription,
    confirmRazorpaySubscription,
} from '../actions/PaymentActions'
import { useNavigate } from 'react-router-dom' // React Router for navigation

export const RazorpaySubscriptionScreen = () => {
    const {
        is_subscription_active,
        user: { name, email },
    } = useSelector((state) => state.auth)
    const [amount, setAmount] = useState('249') // Default subscription amount
    const [isProcessing, setIsProcessing] = useState(false) // Loading state
    const [referralCode, setReferralCode] = useState('') // State for referral code
    const navigate = useNavigate()

    const createSubscription = async () => {
        try {
            const response = await fetch(
                `${SERVER_HOSTNAME}/api/payment/create_razorpay_subscription`, // Your backend API
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email, referralCode }), // Pass the referral code to backend
                }
            )

            const { subscriptionId, discountApplied } = await response.json()

            // Apply discount if referral code is valid
            if (discountApplied) {
                setAmount('149')
            }

            return subscriptionId
        } catch (error) {
            console.error('Failed to create subscription:', error)
            alert('Failed to create subscription. Please try again.')
            return null
        }
    }

    const processPayment = async () => {
        setIsProcessing(true) // Show loading modal before Razorpay loads

        const subscriptionId = await createRazorpaySubscription({
            email,
            referralCode,
        }) // Include referralCode

        if (!subscriptionId) {
            setIsProcessing(false) // Hide loading if subscription creation failed
            return // Exit if subscription creation failed
        }

        const options = {
            key: RAZORYPAY_KEY_ID,
            subscription_id: subscriptionId,
            name: 'ToonTales',
            description: 'Subscription to Premium Plan',
            image: 'https://timeless-avatar.web.app/images/toontales_icon.png',
            prefill: {
                name: name,
                email: email,
            },
            theme: {
                color: '#F37254',
            },
            handler: async (response) => {
                const {
                    razorpay_payment_id,
                    razorpay_subscription_id,
                    razorpay_signature,
                } = response
                const isSuccessful = await confirmRazorpaySubscription({
                    razorpay_payment_id,
                    razorpay_subscription_id,
                    razorpay_signature,
                    referralCode, // Pass referral code to the confirmation
                })

                if (isSuccessful) {
                    alert(
                        'Congratulations! 🎉 You are now Subscribed to premium features of ToonTales'
                    )
                    navigate('/settings')
                }
                setIsProcessing(false) // Hide loading animation after confirmation
            },
            modal: {
                ondismiss: function () {
                    alert('Payment cancelled by user.')
                    setIsProcessing(false) // Hide loading if user cancels
                },
            },
        }

        const rzp = new window.Razorpay(options)
        rzp.open()
    }

    if (is_subscription_active) {
        return (
            <div style={styles.activeContainer}>
                <Typography variant="h6">Subscription is Active</Typography>
            </div>
        )
    }

    return (
        <div style={styles.paymentContainer}>
            <TextField
                label="Referral Code (Optional)"
                variant="outlined"
                value={referralCode}
                onChange={(e) => setReferralCode(e.target.value)} // Update state when user types
                style={styles.referralInput}
            />

            <Typography variant="body2" style={styles.discountText}>
                🎉 Use a referral code to reduce the subscription cost from ₹249
                to ₹149!
            </Typography>

            <Button
                variant="contained"
                style={styles.subscribeButton}
                onClick={processPayment}
            >
                Start Subscription to ToonTales for ₹{amount}
            </Button>

            {/* Modal for processing/loading */}
            <Dialog open={isProcessing} onClose={() => {}}>
                <div style={styles.dialogContent}>
                    <CircularProgress />
                    <Typography variant="h6" style={{ marginTop: '20px' }}>
                        Please wait, preparing subscription...
                    </Typography>
                </div>
            </Dialog>
        </div>
    )
}

const styles = {
    activeContainer: {
        width: '80%',
        justifyContent: 'center',
        marginLeft: '10%',
        marginTop: '10%',
        textAlign: 'center',
    },
    paymentContainer: {
        width: '80%',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '10%',
        marginTop: '20%',
        textAlign: 'center',
    },
    subscribeButton: {
        backgroundColor: themeColors.redcolor,
        borderRadius: '20px',
        padding: '10px 20px',
        color: '#fff',
        marginTop: '20px',
    },
    referralInput: {
        marginBottom: '10px',
        width: '100%',
    },
    discountText: {
        color: themeColors.greenColor,
        marginBottom: '20px',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '30px',
        textAlign: 'center',
    },
}
