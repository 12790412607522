import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom' // React Router's hooks for navigation
import { themeColors } from '../theme'
import { confirmRegistration, resetPassword } from '../actions/AuthActions' // Import your actions
import { ArrowLeftIcon } from '@heroicons/react/solid' // Assuming you're using the heroicons package

export default function VerificationCodeScreen() {
    const navigate = useNavigate()
    const location = useLocation()
    const isSignUp = location.state?.isSignUp
    const email = location.state?.email
    const password = location.state?.password
    const [verificationCode, setVerificationCode] = useState('')

    const onSubmitVerificationCodePressed = async () => {
        let data
        if (isSignUp) {
            const res = await confirmRegistration(verificationCode)
            if (res === 'success') {
                navigate('/login')
            }
        } else {
            data = {
                verification_code: verificationCode,
                email: email,
                password: password,
            }
            const res = await resetPassword(data)
            if (res === 'success') {
                navigate('/login')
            }
        }
    }

    return (
        <div
            style={{
                backgroundColor: themeColors.redcolor,
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <div style={{ padding: '16px' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <button
                        onClick={() => navigate(-1)} // goBack equivalent
                        style={{
                            padding: '8px',
                            borderRadius: '16px 0 0 16px',
                            backgroundColor: themeColors.redcolor,
                        }}
                    >
                        <ArrowLeftIcon
                            style={{
                                width: '20px',
                                height: '20px',
                                color: 'white',
                            }}
                        />
                    </button>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                        src={require('../assets/images/toontales_icon_transparent.png')}
                        alt="Toon Tales Icon"
                        style={{ width: '165px', height: '110px' }}
                    />
                </div>
            </div>

            <div
                style={{
                    flex: 1,
                    backgroundColor: 'white',
                    padding: '32px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                }}
            >
                {/* Form */}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                    }}
                >
                    <label style={{ marginLeft: '16px', color: '#4a5568' }}>
                        Verification Code
                    </label>
                    <input
                        type="text"
                        placeholder="Enter Verification Code"
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                        style={{
                            padding: '16px',
                            backgroundColor: '#f7fafc',
                            color: '#4a5568',
                            borderRadius: '16px',
                            border: 'none',
                            marginBottom: '24px',
                        }}
                    />
                    <button
                        onClick={onSubmitVerificationCodePressed}
                        style={{
                            padding: '16px',
                            borderRadius: '16px',
                            backgroundColor: themeColors.redcolor,
                            color: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                        }}
                    >
                        Submit Verification Code
                    </button>
                </div>

                {/* Links for login */}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '28px',
                    }}
                >
                    <p style={{ color: '#718096', fontWeight: '600' }}>
                        Already have an account?
                    </p>
                    <button
                        onClick={() => navigate('/login')}
                        style={{
                            color: themeColors.blueColor,
                            fontWeight: '600',
                            backgroundColor: 'transparent',
                            border: 'none',
                            cursor: 'pointer',
                            marginLeft: '8px',
                        }}
                    >
                        Login
                    </button>
                </div>
            </div>
        </div>
    )
}
