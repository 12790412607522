import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom' // React Router's hook for navigation
import { themeColors } from '../theme'
import { registerUser } from '../actions/AuthActions' // Import your action
import { ArrowLeftIcon } from '@heroicons/react/solid' // Assuming you're using the heroicons package

export default function SignUpScreen() {
    const navigate = useNavigate()
    const [isVisible, setIsVisible] = useState(false)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const onSignupPressed = async () => {
        const newUser = {
            name,
            email,
            password,
        }

        setIsVisible(true)
        const res = await registerUser(newUser)
        setIsVisible(false)

        if (res === 'success') {
            navigate('/verification-code', { state: { isSignUp: true } })
        }
    }

    return (
        <div
            style={{
                backgroundColor: themeColors.redcolor,
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <div
                style={{
                    paddingTop: '2%',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                }}
            >
                {isVisible && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '2%',
                        }}
                    >
                        <div className="loader"></div>
                    </div>
                )}
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <button
                        onClick={() => navigate(-1)} // goBack equivalent
                        style={{
                            padding: '8px',
                            borderRadius: '16px 0 0 16px',
                            backgroundColor: themeColors.redcolor,
                        }}
                    >
                        <ArrowLeftIcon
                            style={{
                                width: '20px',
                                height: '20px',
                                color: 'white',
                            }}
                        />
                    </button>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                        src={require('../assets/images/toontales_icon_transparent.png')}
                        alt="Toon Tales Icon"
                        style={{ width: '165px', height: '110px' }}
                    />
                </div>
            </div>

            <div
                style={{
                    flex: 1,
                    backgroundColor: 'white',
                    padding: '32px',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                }}
            >
                {/* Form */}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                    }}
                >
                    <label style={{ marginLeft: '16px', color: '#4a5568' }}>
                        Full Name
                    </label>
                    <input
                        type="text"
                        placeholder="Enter Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        style={{
                            padding: '16px',
                            backgroundColor: '#f7fafc',
                            color: '#4a5568',
                            borderRadius: '16px',
                            border: 'none',
                            marginBottom: '12px',
                        }}
                    />
                    <label style={{ marginLeft: '16px', color: '#4a5568' }}>
                        Email Address
                    </label>
                    <input
                        type="email"
                        placeholder="Enter Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={{
                            padding: '16px',
                            backgroundColor: '#f7fafc',
                            color: '#4a5568',
                            borderRadius: '16px',
                            border: 'none',
                            marginBottom: '12px',
                        }}
                    />
                    <label style={{ marginLeft: '16px', color: '#4a5568' }}>
                        Password
                    </label>
                    <input
                        type="password"
                        placeholder="Enter Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        style={{
                            padding: '16px',
                            backgroundColor: '#f7fafc',
                            color: '#4a5568',
                            borderRadius: '16px',
                            border: 'none',
                            marginBottom: '24px',
                        }}
                    />
                    <button
                        onClick={onSignupPressed}
                        style={{
                            padding: '16px',
                            borderRadius: '16px',
                            backgroundColor: themeColors.redcolor,
                            color: 'white',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                        }}
                    >
                        Sign Up
                    </button>
                </div>

                {/* Links for login */}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '28px',
                    }}
                >
                    <p style={{ color: '#718096', fontWeight: '600' }}>
                        Already have an account?
                    </p>
                    <button
                        onClick={() => navigate('/login')}
                        style={{
                            color: themeColors.blueColor,
                            fontWeight: '600',
                            backgroundColor: 'transparent',
                            border: 'none',
                            cursor: 'pointer',
                            marginLeft: '8px',
                        }}
                    >
                        Login
                    </button>
                </div>
            </div>
        </div>
    )
}
