import axios from "axios";
import { ReduxStore as redux_store } from "../ReduxStore";
import { SET_USER_COUNTRY } from "./types";

export const getUserCountry = async () => {
  return axios
    .get("http://ip-api.com/json")
    .then((res) => {
      const response = res.data;
      const { countryCode, timezone } = response;
      redux_store.dispatch({
        type: SET_USER_COUNTRY,
        payload: countryCode,
      });
      return { countryCode, timezone };
    })
    .catch((err) => {
      console.error("getPreviousMessages error is: ", err);
      return false;
    });
};
