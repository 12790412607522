import React, { useState, useEffect } from 'react'
import {
    Button,
    Avatar,
    Badge,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Typography,
    TextField,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    IconButton,
} from '@mui/material' // Material UI components
import { useNavigate } from 'react-router-dom' // React Router for navigation
import { themeColors } from '../theme' // Import custom theme
import { addAvatar, searchForAvatars } from '../actions/AvatarActions' // Import actions
import { useSelector } from 'react-redux'
import SpeechRecognition, {
    useSpeechRecognition,
} from 'react-speech-recognition'
import { Mic as MicIcon } from '@mui/icons-material' // Microphone icon from Material UI
import Lottie from 'react-lottie-player'
import voiceWaveAnimation from '../assets/lottie-animations/voice-wave.json'

export const CreateAvatarScreen = () => {
    const navigate = useNavigate()
    const [dataSource, setDataSource] = useState([])
    const [visible, setVisible] = useState(false)
    const [searchingVisible, setSearchingVisible] = useState(false)
    const [progressVisible, setProgressVisible] = useState(false)
    const [selectedAvatarUrl, setSelectedAvatarUrl] = useState('')
    const [text, setText] = useState('')
    const [goal, setGoal] = useState('')
    const [customGoal, setCustomGoal] = useState('')
    const [limitReachedVisible, setLimitReachedVisible] = useState(false)
    const [avatarLanguage, setAvatarLanguage] = useState('English') // New state for avatar language
    const { customAvatars, is_subscription_active } = useSelector(
        (state) => state.auth
    )

    const [isRecording, setIsRecording] = useState(false) // State to handle recording modal

    // Voice recognition states and handlers
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
    } = useSpeechRecognition({
        onEnd: () => {
            setIsRecording(false) // Close the modal when recording stops
            searchClicked() // Trigger the search after stopping
        },
    })

    const searchClicked = async () => {
        setSearchingVisible(true)
        setDataSource([])
        const urls = await searchForAvatars(text)
        setSearchingVisible(false)
        if (urls.length === 0) {
            alert(
                `Couldn't find any avatars for the given character/person. Please try a different character/person.`
            )
        } else {
            setDataSource(urls)
        }
    }

    const onAvatarSelected = async () => {
        // Check if subscription is inactive and custom avatar limit is reached
        if (!is_subscription_active && customAvatars.length >= 2) {
            setLimitReachedVisible(true) // Show limit reached dialog
            return
        }

        let avatarGoal =
            goal === '' && customGoal === '' ? 'no_goal' : goal || customGoal
        const data = {
            avatarName: text,
            avatarProfession: avatarGoal,
            imageUrl: selectedAvatarUrl,
            avatarLanguage, // Include avatarLanguage in the request
        }
        setProgressVisible(true)
        const response = await addAvatar(data)
        setProgressVisible(false)
        if (!response) {
            alert(
                'Failed to add the avatar. Please try again with a different name.'
            )
        } else {
            alert('Avatar added successfully! Please select it to chat.')
            navigate('/select-avatar', { state: { isCustomAvatars: 'true' } })
        }
    }

    // Start voice recording
    const startListening = () => {
        resetTranscript() // Clear previous transcript
        setIsRecording(true) // Show recording modal
        SpeechRecognition.startListening({ continuous: false }) // Start listening
    }

    // Stop voice recording manually
    const stopListening = () => {
        setIsRecording(false) // Hide recording modal
        SpeechRecognition.stopListening() // Stop listening
    }

    // Sync voice input to text field
    useEffect(() => {
        setText(transcript) // Update the input with the speech transcript
    }, [transcript])

    return (
        <div style={styles.containerStyle}>
            {/* Search Input with voice recognition */}
            <div style={styles.searchContainerStyle}>
                <TextField
                    label="Enter your favorite character or person"
                    variant="outlined"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    style={{ flex: 1, marginRight: '10px' }}
                />

                {/* Voice input button, only show if browser supports speech recognition */}
                {browserSupportsSpeechRecognition && (
                    <IconButton
                        onClick={startListening}
                        color={listening ? 'secondary' : 'primary'}
                        style={{ marginRight: '10px' }}
                    >
                        <MicIcon />
                    </IconButton>
                )}

                <Button
                    variant="contained"
                    onClick={searchClicked}
                    style={{
                        backgroundColor: themeColors.blueColor,
                        borderRadius: '20px',
                    }}
                >
                    Search
                </Button>
            </div>

            {/* Avatar Grid */}
            <div style={styles.listContainer}>
                {dataSource.length !== 0 && (
                    <Typography variant="h6" gutterBottom>
                        Choose an image for your avatar
                    </Typography>
                )}

                <Grid container spacing={2}>
                    {dataSource.map((item, index) => (
                        <Grid
                            item
                            xs={4}
                            key={index}
                            style={{ textAlign: 'center' }}
                        >
                            <Avatar
                                src={item}
                                sx={{
                                    width: 80,
                                    height: 80,
                                    backgroundColor: themeColors.whiteColor,
                                    border: `2px solid ${themeColors.redcolor}`,
                                }}
                                onClick={() => {
                                    setSelectedAvatarUrl(item)
                                    setVisible(true)
                                }}
                            />
                            <Badge
                                color="success"
                                overlap="circular"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>

                {/* Dialog for Avatar Creation */}
                <Dialog
                    open={visible}
                    onClose={() => setVisible(false)}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>{text}</DialogTitle>
                    <DialogContent>
                        <Avatar
                            src={selectedAvatarUrl}
                            sx={{ width: 150, height: 150 }}
                        />
                        <Typography>Select a goal for the avatar:</Typography>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Select a Goal</InputLabel>
                            <Select
                                value={goal}
                                onChange={(e) => {
                                    setCustomGoal('')
                                    setGoal(e.target.value)
                                }}
                                label="Select a Goal"
                            >
                                <MenuItem value="no_goal">No goal</MenuItem>
                                <MenuItem value="english_language_tutor">
                                    English Tutor
                                </MenuItem>
                                <MenuItem value="french_language_tutor">
                                    French Tutor
                                </MenuItem>
                                <MenuItem value="coding_tutor">
                                    Coding Tutor
                                </MenuItem>
                                <MenuItem value="drawing_tutor">
                                    Drawing Tutor
                                </MenuItem>
                                <MenuItem value="origami_tutor">
                                    Origami Tutor
                                </MenuItem>
                                <MenuItem value="homework_tutor">
                                    Homework Tutor
                                </MenuItem>
                                <MenuItem value="history_lessons_tutor">
                                    History Tutor
                                </MenuItem>
                            </Select>
                        </FormControl>

                        <Typography gutterBottom>
                            Or enter a custom goal:
                        </Typography>
                        <TextField
                            label="Custom Goal"
                            variant="outlined"
                            fullWidth
                            value={customGoal}
                            onChange={(e) => {
                                setGoal('')
                                setCustomGoal(e.target.value)
                            }}
                        />

                        {/* Avatar Language Selection */}
                        <Typography gutterBottom>
                            Select Avatar Language:
                        </Typography>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Avatar Language</InputLabel>
                            <Select
                                value={avatarLanguage}
                                onChange={(e) =>
                                    setAvatarLanguage(e.target.value)
                                }
                                label="Avatar Language"
                            >
                                <MenuItem value="English">English</MenuItem>
                                <MenuItem value="Hindi">Hindi</MenuItem>
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={onAvatarSelected}
                            style={{
                                backgroundColor: themeColors.redcolor,
                                color: 'white',
                            }}
                        >
                            Create {text}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Voice Recording Modal */}
                {isRecording && (
                    <Dialog open={isRecording}>
                        <DialogContent>
                            <Lottie
                                loop
                                animationData={voiceWaveAnimation}
                                play
                                style={{ width: 200, height: 200 }}
                            />
                            <Typography>Recording...</Typography>
                            <Button
                                onClick={stopListening}
                                style={{
                                    backgroundColor: 'red',
                                    color: 'white',
                                }}
                            >
                                Stop Recording
                            </Button>
                        </DialogContent>
                    </Dialog>
                )}

                {/* Searching Overlay */}
                <Dialog open={searchingVisible}>
                    <DialogContent>
                        <Typography>
                            Searching for avatars. Please wait...
                        </Typography>
                        <CircularProgress color="primary" />
                    </DialogContent>
                </Dialog>

                {/* Progress Overlay */}
                <Dialog open={progressVisible}>
                    <DialogContent>
                        <Typography>
                            Adding avatar. This may take some time. Please
                            wait...
                        </Typography>
                        <CircularProgress color="primary" />
                    </DialogContent>
                </Dialog>

                {/* Limit Reached Dialog */}
                <Dialog
                    open={limitReachedVisible}
                    onClose={() => setLimitReachedVisible(false)}
                >
                    <DialogTitle>Limit Reached</DialogTitle>
                    <DialogContent>
                        <Typography>
                            You have reached the limit of 2 custom avatars for
                            free users. Please upgrade your subscription to add
                            more custom avatars and enjoy more features 🤗
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => navigate('/upgrade-subscription')}
                            style={{
                                backgroundColor: themeColors.redcolor,
                                color: 'white',
                            }}
                        >
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}

const styles = {
    containerStyle: {
        marginLeft: '5%',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '6%',
        marginBottom: '6%',
    },
    searchContainerStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '20px',
    },
    listContainer: {},
}
