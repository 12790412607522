import axios from 'axios'
import { SET_SUBSCRIPTION_STATUS } from './types'
import { ReduxStore as redux_store } from '../ReduxStore'

export const createRazorpaySubscription = async (data) => {
    return axios
        .post(`/api/payment/create_razorpay_subscription`, data)
        .then((res) => {
            const response = res.data
            return response.subscriptionId
        })
        .catch((err) => {
            //console.error("addAvatar error is: ", err);
            return null
        })
}

export const confirmRazorpaySubscription = async (data) => {
    return axios
        .post(`/api/payment/confirm_razorpay_subscription`, data)
        .then((res) => {
            const response = res.data
            const is_subscription_active = response.isSuccessful
            redux_store.dispatch({
                type: SET_SUBSCRIPTION_STATUS,
                payload: is_subscription_active,
            })
            return is_subscription_active
        })
        .catch((err) => {
            //console.error("addAvatar error is: ", err);
            return null
        })
}

export const cancelRazorpaySubscription = async (data) => {
    return axios
        .post(`/api/payment/cancel_razorpay_subscription`, data)
        .then((res) => {
            const response = res.data
            const is_subscription_active = response.is_subscription_active
            redux_store.dispatch({
                type: SET_SUBSCRIPTION_STATUS,
                payload: is_subscription_active,
            })
            return true
        })
        .catch((err) => {
            //console.error("addAvatar error is: ", err);
            return null
        })
}

export const getStripePortalSessionUrl = async () => {
    return axios
        .get(`/api/payment/portal_session_url`)
        .then((res) => {
            const response = res.data
            const portalSessionUrl = response.portalSessionUrl
            return portalSessionUrl
        })
        .catch((err) => {
            console.error('getStripePortalSessionUrl error is: ', err)
            return null
        })
}

export const cancelStripeSubscription = async (data) => {
    return axios
        .post(`/api/payment/cancel_stripe_subscription`, data)
        .then((res) => {
            const response = res.data
            const is_subscription_active = response.is_subscription_active
            redux_store.dispatch({
                type: SET_SUBSCRIPTION_STATUS,
                payload: is_subscription_active,
            })
            return true
        })
        .catch((err) => {
            //console.error("addAvatar error is: ", err);
            return null
        })
}
