import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import AppNavigation from './navigation/AppNavigation'
import { ReduxStore as redux_store } from './ReduxStore'
//import './App.css' // Add global styles here

export default function App() {
    const [fontsLoaded, setFontsLoaded] = useState(true)

    useEffect(() => {
        // Simulate font loading, replace with web font loading logic if needed
        setFontsLoaded(true)
    }, [])

    if (!fontsLoaded) {
        return <div>Loading...</div>
    }

    return (
        <Provider store={redux_store}>
            <AppNavigation />
        </Provider>
    )
}
