import {
    SET_CURRENT_USER,
    USER_LOADING,
    SET_USER_COUNTRY,
    SET_USER_CREDITS,
    SET_AVATAR,
    GET_AVATARS,
    SET_AVATAR_ICON_CLICKED,
    GET_CUSTOM_AVATARS,
    SET_SUBSCRIPTION_STATUS,
} from '../actions/types'

const isEmpty = require('is-empty')

const initialState = {
    isAuthenticated: false,
    user: {},
    creditsInMinutes: 0,
    is_subscription_active: false,
    loading: false,
    countryCode: 'IN',
    selectedAvatar: {
        name: 'Winnie The Pooh',
        description: 'Winnie The Pooh of the Hundred Acre Wood',
        character: 'sunny, cute, simple and happy',
        url: 'https://timeless-avatar.web.app/images/characters/winnie_the_pooh.png',
    },
    avatars: [],
    customAvatars: [],
    isAvatarIconClicked: false,
}

export const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload,
                creditsInMinutes: action.payload.creditsInMinutes,
                is_subscription_active: action.payload.is_subscription_active,
            }
        case SET_SUBSCRIPTION_STATUS:
            return {
                ...state,
                is_subscription_active: action.payload,
            }
        case USER_LOADING:
            return {
                ...state,
                loading: true,
            }
        case SET_USER_COUNTRY:
            return {
                ...state,
                countryCode: action.payload,
            }
        case SET_USER_CREDITS:
            return {
                ...state,
                creditsInMinutes: action.payload,
            }
        case SET_AVATAR:
            return {
                ...state,
                selectedAvatar: action.payload,
            }
        case GET_AVATARS:
            return {
                ...state,
                avatars: action.payload,
            }
        case GET_CUSTOM_AVATARS:
            return {
                ...state,
                customAvatars: action.payload,
            }
        case SET_AVATAR_ICON_CLICKED:
            return {
                ...state,
                isAvatarIconClicked: !state.isAvatarIconClicked,
            }

        default:
            return state
    }
}
