import axios from "axios";
import { ReduxStore as redux_store } from "../ReduxStore";
import { GET_AVATARS, GET_CUSTOM_AVATARS, SET_USER_CREDITS } from "./types";

export const getPreviousMessages = async (selectedAvatar, isCustom) => {
  return axios
    .get(
      `/api/chat/getPreviousMessages?avatarName=${selectedAvatar}&isCustom=${isCustom}`
    )
    .then((res) => {
      const response = res.data;
      return response.messages;
    })
    .catch((err) => {
      console.error("getPreviousMessages error is: ", err);
      return [];
    });
};

export const getAvatars = async () => {
  return axios
    .get(`/api/chat/getAvatars`)
    .then((res) => {
      const response = res.data;
      redux_store.dispatch({ type: GET_AVATARS, payload: response.avatars });
      return response.avatars;
    })
    .catch((err) => {
      console.error("getAvatars error is: ", err);
      return [];
    });
};

export const getCustomAvatars = async () => {
  return axios
    .get(`/api/avatar/getCustomAvatars`)
    .then((res) => {
      const response = res.data;
      redux_store.dispatch({
        type: GET_CUSTOM_AVATARS,
        payload: response.avatars,
      });
      return response.avatars;
    })
    .catch((err) => {
      console.error("getCustomAvatars error is: ", err);
      return [];
    });
};

export const chatWithOpenAI = async (messageData) => {
  return axios
    .post("/api/chat/chatWithOpenAI", messageData)
    .then((res) => {
      const response = res.data;
      const { code, creditsInMinutes, messages } = response;
      if (code === "SUCCESS") {
        redux_store.dispatch({
          type: SET_USER_CREDITS,
          payload: creditsInMinutes,
        });
        return { code: code, messages };
      } else if (code === "INSUFFICIENT_CREDITS") {
        return { code: code, messages: [] };
      }
    })
    .catch((err) => {
      console.error("chatWithOpenAI error is: ", err);
      return { messages: [] };
    });
};
