import React from 'react'
import { themeColors } from '../theme'

export const AboutScreen = () => {
    return (
        <div style={styles.container}>
            <img
                src={require('../assets/images/toontales_icon_transparent.png')}
                alt="Toon Tales Icon"
                style={{ width: 200, height: 200 }}
            />
            <p style={styles.about}>Toon Tales</p>
            <p style={styles.aboutContent}>
                For any queries or issues please reach us at
                timelesstechlabs@gmail.com
            </p>
            <p style={styles.version}>v0.0.4</p>
        </div>
    )
}

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: themeColors.redcolor,
        height: '100vh',
        textAlign: 'center',
    },
    about: {
        fontFamily: 'albertSans',
        color: themeColors.whiteColor,
        fontSize: '22px',
        marginTop: '2%',
    },
    aboutContent: {
        fontFamily: 'albertSans',
        color: themeColors.whiteColor,
        fontSize: '16px',
        marginTop: '0%',
    },
    version: {
        marginBottom: '20px',
        fontFamily: 'albertSans',
        color: themeColors.whiteColor,
    },
}
