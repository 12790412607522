import { createStore, combineReducers, applyMiddleware } from 'redux'
import { thunk } from 'redux-thunk'
import { composeWithDevTools } from '@redux-devtools/extension'
import { AuthReducer } from './reducers/AuthReducer'

const initialState = {}

const rootReducer = combineReducers({
    auth: AuthReducer,
})

export const ReduxStore = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(thunk))
)
