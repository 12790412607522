import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { logoutUser } from '../actions/AuthActions'
import { themeColors } from '../theme'
import { MaterialButtonWithVioletText } from '../components/MaterialButtonWithVioletText'
import { Description, Info, LocalGasStation } from '@mui/icons-material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'

export const SettingsScreen = () => {
    const navigate = useNavigate()
    const { creditsInMinutes, is_subscription_active, user } = useSelector(
        (state) => state.auth
    )

    const onLogoutPressed = async () => {
        await logoutUser()
    }

    return (
        <div style={styles.container}>
            <h2 style={styles.settings}>⚙️ Account Settings</h2>

            {/* Subscription or Credit Info */}
            {is_subscription_active ? (
                <div style={styles.creditsButton}>
                    <p
                        style={styles.credits}
                        onClick={() => navigate('/upgrade-subscription')}
                    >
                        🎉 Congratulations! You're subscribed to{' '}
                        <span style={styles.highlight}>ToonTales Premium</span>!
                    </p>
                </div>
            ) : (
                <div
                    style={styles.creditsButton}
                    onClick={() => navigate('/upgrade-subscription')}
                >
                    <p style={styles.credits}>
                        💰 Available Credits: {creditsInMinutes} minutes
                    </p>
                    <p style={styles.creditsSubText}>
                        🕒 Credits renew every month. Unlock unlimited and
                        premium features by subscribing to{' '}
                        <span style={styles.highlight}>ToonTales</span>! 🚀
                    </p>
                </div>
            )}

            {/* Affiliate Program Section */}
            <div style={styles.affiliateProgramContainer}>
                <h3 style={styles.affiliateHeader}>
                    💼 Join Our Affiliate Program!
                </h3>
                <p style={styles.affiliateDetails}>
                    🎁 By joining our affiliate program, you can earn money
                    every month! When someone uses your referral code, they get
                    the ToonTales subscription for
                    <span style={styles.highlight}> ₹149/month</span> (instead
                    of ₹249/month), and you'll earn
                    <span style={styles.highlight}> ₹49/month</span> for each
                    user who subscribes using your code.
                </p>
                <p style={styles.affiliateDetails}>
                    Share your referral code and start earning today!
                </p>
            </div>

            {/* Referral Code */}
            {user?.referralCode && (
                <div style={styles.referralCodeContainer}>
                    <p style={styles.referralCodeLabel}>
                        🎁 Your Referral Code:
                    </p>
                    <p style={styles.referralCode}>{user.referralCode}</p>
                </div>
            )}

            {/* List of other settings */}
            <div style={styles.listContainer}>
                <div
                    style={styles.button}
                    onClick={() => navigate('/upgrade-subscription')}
                >
                    <div style={styles.iconRow}>
                        <LocalGasStation style={styles.creditsIcon} />
                        <p style={styles.about}>Manage Subscription</p>
                    </div>
                    <ArrowRightIcon sx={styles.icon2} />
                </div>

                <div
                    style={styles.button}
                    onClick={() => navigate('/terms-conditions')}
                >
                    <div style={styles.iconRow}>
                        <Description style={styles.termsIcon} />
                        <p style={styles.about}>📜 Terms & Privacy Policy</p>
                    </div>
                    <ArrowRightIcon sx={styles.icon2} />
                </div>

                <div style={styles.button} onClick={() => navigate('/about')}>
                    <div style={styles.iconRow}>
                        <Info style={styles.aboutIcon} />
                        <p style={styles.about}>ℹ️ About & Contact Us</p>
                    </div>
                    <ArrowRightIcon sx={styles.icon2} />
                </div>
            </div>

            {/* Logout Button */}
            <MaterialButtonWithVioletText
                style={styles.materialButtonWithVioletText}
                onClick={onLogoutPressed}
                buttonText="🚪 Log Out"
            />

            <p style={styles.version}>v0.0.4</p>
        </div>
    )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: themeColors.greyColor,
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        minHeight: '100vh',
    },
    settings: {
        fontFamily: 'albertSans',
        color: themeColors.textBlackColor,
        fontSize: '22px',
        textAlign: 'center',
        marginTop: '10px',
    },
    creditsButton: {
        borderRadius: '20px',
        width: '50%',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '15px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    },
    credits: {
        fontFamily: 'albertSans',
        color: themeColors.textBlackColor,
        fontSize: '14px',
        textAlign: 'center',
    },
    creditsSubText: {
        fontFamily: 'albertSans',
        color: themeColors.textBlackColor,
        fontSize: '10px',
        textAlign: 'center',
        marginTop: '5px',
    },
    highlight: {
        fontWeight: 'bold',
        color: themeColors.purpleColor,
    },
    listContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '20px',
    },
    button: {
        borderRadius: '20px',
        width: '90%',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '12px',
        margin: '10px 0',
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
        cursor: 'pointer',
    },
    iconRow: {
        display: 'flex',
        alignItems: 'center',
    },
    creditsIcon: {
        color: themeColors.greenColor,
        fontSize: '40px',
    },
    aboutIcon: {
        color: 'grey',
        fontSize: '40px',
    },
    termsIcon: {
        color: 'grey',
        fontSize: '40px',
    },
    about: {
        fontFamily: 'albertSans',
        color: themeColors.textBlackColor,
        fontSize: '16px',
        marginLeft: '10px',
    },
    icon2: {
        color: 'rgba(128,128,128,1)',
        fontSize: '30px',
    },
    materialButtonWithVioletText: {
        marginTop: '40px',
        padding: '12px 24px',
        borderRadius: '25px',
        backgroundColor: 'grey',
        color: 'white',
        cursor: 'pointer',
        fontFamily: 'albertSans',
        fontSize: '16px',
    },
    referralCodeContainer: {
        marginTop: '30px',
        padding: '15px',
        borderRadius: '15px',
        backgroundColor: 'white',
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    referralCodeLabel: {
        fontFamily: 'albertSans',
        fontSize: '14px',
        color: themeColors.textBlackColor,
    },
    referralCode: {
        fontFamily: 'albertSans',
        fontSize: '18px',
        fontWeight: 'bold',
        color: themeColors.purpleColor,
        marginTop: '5px',
    },
    version: {
        marginTop: '20px',
        fontFamily: 'albertSans',
        fontSize: '12px',
        color: themeColors.textBlackColor,
    },
    affiliateProgramContainer: {
        marginTop: '20px',
        padding: '20px',
        borderRadius: '20px',
        backgroundColor: 'white',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
    },
    affiliateHeader: {
        fontFamily: 'albertSans',
        color: themeColors.textBlackColor,
        fontSize: '18px',
        fontWeight: 'bold',
    },
    affiliateDetails: {
        fontFamily: 'albertSans',
        color: themeColors.textBlackColor,
        fontSize: '14px',
        marginTop: '10px',
    },
}
