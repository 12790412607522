// Authentication
export const USER_LOADING = 'USER_LOADING'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const SET_USER_COUNTRY = 'SET_USER_COUNTRY'
export const SET_USER_CREDITS = 'SET_USER_CREDITS'
export const SET_AVATAR = 'SET_AVATAR'
export const GET_AVATARS = 'GET_AVATARS'
export const GET_CUSTOM_AVATARS = 'GET_CUSTOM_AVATARS'
export const SET_AVATAR_ICON_CLICKED = 'SET_AVATAR_ICON_CLICKED'
export const SET_SUBSCRIPTION_STATUS = 'SET_SUBSCRIPTION_STATUS'
