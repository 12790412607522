import axios from "axios";
import { GET_CUSTOM_AVATARS } from "./types";
import { ReduxStore as redux_store } from "../ReduxStore";

export const searchForAvatars = async (queryString) => {
  return axios
    .get(`/api/avatar/searchForAvatars?queryString=${queryString}`)
    .then((res) => {
      const response = res.data;
      return response.urls;
    })
    .catch((err) => {
      console.error("searchForAvatars error is: ", err);
      return [];
    });
};

export const addAvatar = async (data) => {
  return axios
    .post(`/api/avatar/addAvatar`, data)
    .then((res) => {
      const response = res.data;
      redux_store.dispatch({
        type: GET_CUSTOM_AVATARS,
        payload: response.avatars,
      });
      return response.avatars;
    })
    .catch((err) => {
      //console.error("addAvatar error is: ", err);
      return null;
    });
};
