import React from 'react'

export const TermsAndConditionScreen = () => {
    return (
        <div style={styles.container}>
            <h2 style={styles.termsAndConditionHeading}>
                Terms & Conditions and Privacy Policy
            </h2>
            <p style={styles.termsAndCondition}>
                In this document, ToonTales represents the ToonTales app, its
                owners, and developers.
            </p>
            <h3 style={styles.termsAndConditionSubHeading}>
                Terms and Conditions
            </h3>
            <p style={styles.termsAndCondition}>
                Welcome to ToonTales (the "App"). By accessing or using the App,
                you agree to be bound by the following terms and conditions
                ("Terms and Conditions"). If you do not agree to these Terms and
                Conditions, you may not use the App.
            </p>
            <p style={styles.termsAndCondition}>
                Collection of Information: The App may collect certain
                information from you, including your username, email address,
                profile picture, and location data. We use this information to
                provide you with personalized features and services within the
                App. We may also use Google Analytics to collect user
                information, such as device type, operating system, and usage
                statistics. This information helps us improve the App and
                provide better services to our users.
            </p>
            <p style={styles.termsAndCondition}>
                Use of Information: We may use the information we collect to
                improve and optimize the App's functionality, provide a better
                user experience, and develop new features and services. We may
                also use the information to communicate with you about updates,
                promotions, and other relevant information related to the App.
            </p>
            <p style={styles.termsAndCondition}>
                Disclosure of Information: We do not sell or share your personal
                information with third parties without your consent. However, we
                may disclose your information if required by law, court order,
                or governmental agency.
            </p>
            <p style={styles.termsAndCondition}>
                Security: We take the security of your information seriously and
                take reasonable measures to protect your information from
                unauthorized access or disclosure. However, no method of
                transmission over the Internet or electronic storage is
                completely secure, and we cannot guarantee the absolute security
                of your information.
            </p>
            <p style={styles.termsAndCondition}>
                Changes to the Terms and Conditions: We may modify these Terms
                and Conditions at any time without prior notice. Your continued
                use of the App after such modifications will constitute your
                acceptance of the revised Terms and Conditions.
            </p>
            <p style={styles.termsAndCondition}>
                Disclaimer: The App is provided on an "as is" basis, and we make
                no representations or warranties of any kind, express or
                implied, regarding the App's availability, accuracy,
                reliability, or suitability for any purpose. We do not warrant
                that the App will be error-free or uninterrupted.
            </p>
            <p style={styles.termsAndCondition}>
                Limitation of Liability: In no event shall we be liable for any
                direct, indirect, incidental, special, or consequential damages
                arising out of or in connection with the use of the App,
                including but not limited to loss of profits, data, or other
                intangible losses, even if we have been advised of the
                possibility of such damages.
            </p>
            <p style={styles.termsAndCondition}>
                Governing Law: These Terms and Conditions shall be governed by
                and construed in accordance with the laws of the jurisdiction in
                which we are located.
            </p>
            <p style={styles.termsAndCondition}>
                Payment & Subscription Terms: Payment will be charged to Google
                Play Store / iTunes at purchase confirmation. Subscription
                automatically renews unless auto-renew is turned off at least 24
                hours before the end of the current period. Account will be
                charged for renewal within 24 hours before the end of the
                current period. Subscriptions can be cancelled anytime for any
                reason. You can disable auto-renewal 24 hours by going to your
                Google Play Store / iTunes accounts before the end of the
                current billing period to avoid surprise charges. You will
                continue to have access to the premium features until the end of
                your subscription billing period.
            </p>

            <h3 style={styles.termsAndConditionSubHeading}>Privacy Policy</h3>
            <p style={styles.termsAndCondition}>
                This Privacy Policy describes how we collect, use, and protect
                your personal information. By accessing or using the App, you
                consent to the collection, use, and disclosure of your personal
                information in accordance with this Privacy Policy.
            </p>
            <p style={styles.termsAndCondition}>
                Collection of Information: We may collect certain information
                from you, including your username, email address, profile
                picture, and location data. We use this information to provide
                you with personalized features and services within the App. We
                may also use Google Analytics to collect user information, such
                as device type, operating system, and usage statistics. This
                information helps us improve the App and provide better services
                to our users.
            </p>
            <p style={styles.termsAndCondition}>
                Use of Information: We may use the information we collect to
                improve and optimize the App's functionality, provide a better
                user experience, and develop new features and services. We may
                also use the information to communicate with you about updates,
                promotions, and other relevant information related to the App.
            </p>
            <p style={styles.termsAndCondition}>
                Disclosure of Information: We do not sell or share your personal
                information with third parties without your consent. However, we
                may disclose your information if required by law, court order,
                or governmental agency.
            </p>
            <p style={styles.termsAndCondition}>
                Security: We take the security of your information seriously and
                take reasonable measures to protect your information from
                unauthorized access or disclosure. However, no method of
                transmission over the internet or electronic storage is
                completely secure, and we cannot guarantee the absolute security
                of your information.
            </p>
            <p style={styles.termsAndCondition}>
                Third-Party Links: The App may contain links to third-party
                websites or services that are not owned or controlled by us. We
                are not responsible for the privacy practices or content of
                these third-party websites or services, and we encourage you to
                review their respective privacy policies.
            </p>
            <p style={styles.termsAndCondition}>
                Changes to the Privacy Policy: We may modify this Privacy Policy
                at any time without prior notice. Your continued use of the App
                after such modifications will constitute your acceptance of the
                revised Privacy Policy.
            </p>
            <p style={styles.termsAndCondition}>
                Contact Us: If you have any questions or concerns about this
                Privacy Policy, or if you would like to exercise your privacy
                rights, please contact us at timelesstechlabs@gmail.com.
            </p>
        </div>
    )
}

const styles = {
    container: {
        maxWidth: '100%',
        padding: '20px',
        overflowY: 'scroll',
        marginBottom: '2%',
    },
    termsAndConditionHeading: {
        fontFamily: 'albertSans',
        color: '#000', // Use your theme color
        fontSize: '20px',
        textAlign: 'center',
        marginTop: '20px',
        marginBottom: '20px',
    },
    termsAndConditionSubHeading: {
        fontFamily: 'albertSans',
        color: '#000',
        fontSize: '16px',
        textAlign: 'center',
        marginTop: '20px',
        marginBottom: '20px',
    },
    termsAndCondition: {
        fontFamily: 'albertSans',
        color: '#000',
        fontSize: '12px',
        textAlign: 'center',
        marginTop: '20px',
        marginBottom: '20px',
        lineHeight: '1.5', // Optional for better readability
        maxWidth: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}
