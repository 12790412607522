import React from 'react'

export const MaterialButtonWithVioletText = ({
    style,
    onClick,
    buttonText,
}) => {
    return (
        <button style={{ ...styles.container, ...style }} onClick={onClick}>
            <span style={styles.logOut}>{buttonText}</span>
        </button>
    )
}

const styles = {
    container: {
        backgroundColor: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        minWidth: '88px',
        paddingLeft: '16px',
        paddingRight: '16px',
        border: 'none',
        cursor: 'pointer',
    },
    logOut: {
        color: 'white',
        fontSize: '14px',
        fontFamily: 'albertSans',
    },
}
