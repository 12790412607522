export const themeColors = {
    bg: '#877dfa',
    blueColor: '#3F51B5',
    //redcolor: "#d3007d",
    redcolor: '#983298',
    greyColor: '#e5e7e9',
    greenColor: '#82e0aa',
    whiteColor: '#ffffff',
    textBlackColor: '#121212',
    blackColor: '#111111',
    darkVioletColor: '#610B5E',
}
