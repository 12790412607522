import React from 'react'
import { useNavigate } from 'react-router-dom' // React Router for navigation
import { useSelector } from 'react-redux'
import Avatar from '@mui/material/Avatar' // Using Material UI for Avatar
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount' // Import the specific icon
import AddCircleIcon from '@mui/icons-material/AddCircle' // Import the specific icon
import CheckCircleIcon from '@mui/icons-material/CheckCircle' // Import the specific icon
import ArrowRightIcon from '@mui/icons-material/ArrowRight' // Import the specific icon
import { themeColors } from '../theme'

export const DashboardScreen = () => {
    const navigate = useNavigate()
    const { selectedAvatar } = useSelector((state) => state.auth)
    const avatarRole = selectedAvatar?.profession
        ? selectedAvatar.profession.replace(/_/g, ' ')
        : ''

    return (
        <div style={styles.container}>
            <div style={styles.continueChat} onClick={() => navigate('/chat')}>
                <Avatar
                    alt={selectedAvatar.name}
                    src={selectedAvatar.url}
                    sx={{ width: 150, height: 150, marginTop: '1%' }}
                />
                <div style={styles.continueChatTextContainer}>
                    <p style={styles.continueChatText}>
                        {selectedAvatar.profession === 'no_goal'
                            ? `Tap to continue where you left off with ${selectedAvatar.name}`
                            : `Tap to continue where you left off with ${selectedAvatar.name}, your ${avatarRole}`}
                    </p>
                </div>
            </div>

            <div style={styles.listContainer}>
                <div
                    style={styles.button}
                    onClick={() =>
                        navigate('/select-avatar', {
                            state: { isCustomAvatars: 'false' },
                        })
                    }
                >
                    <div style={styles.iconRow}>
                        <SwitchAccountIcon
                            sx={{ color: themeColors.redcolor, fontSize: 40 }}
                        />
                        <p style={styles.about}>Select ToonTales Avatar</p>
                    </div>
                    <ArrowRightIcon sx={styles.icon2} />
                </div>

                <div
                    style={styles.button}
                    onClick={() => navigate('/create-avatar')}
                >
                    <div style={styles.iconRow}>
                        <AddCircleIcon
                            sx={{ color: themeColors.greenColor, fontSize: 40 }}
                        />
                        <p style={styles.about}>Create Avatar</p>
                    </div>
                    <ArrowRightIcon sx={styles.icon2} />
                </div>

                <div
                    style={styles.button}
                    onClick={() =>
                        navigate('/select-avatar', {
                            state: { isCustomAvatars: 'true' },
                        })
                    }
                >
                    <div style={styles.iconRow}>
                        <CheckCircleIcon
                            sx={{ color: themeColors.blueColor, fontSize: 40 }}
                        />
                        <p style={styles.about}>Select Created Avatar</p>
                    </div>
                    <ArrowRightIcon sx={styles.icon2} />
                </div>
            </div>
        </div>
    )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: themeColors.greyColor,
        height: '100vh',
        paddingTop: '0%',
    },
    continueChat: {
        marginTop: '0%',
        height: '30%',
        width: '90%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '20px',
        backgroundColor: 'rgba(130, 224, 170, 0.5)',
        cursor: 'pointer',
    },
    continueChatTextContainer: {
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    continueChatText: {
        fontFamily: 'albertSans',
        color: themeColors.textBlackColor,
        fontSize: '16px',
        textAlign: 'center',
        margin: '10px 0',
    },
    listContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '20px',
        width: '90%',
        height: '60px',
        backgroundColor: 'white',
        marginTop: '20px',
        cursor: 'pointer',
        padding: '0 10px',
    },
    iconRow: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    about: {
        fontFamily: 'albertSans',
        color: themeColors.textBlackColor,
        fontSize: '16px',
        marginLeft: '11px',
    },
    icon2: {
        color: 'rgba(128,128,128,1)',
        fontSize: '40px',
    },
}
