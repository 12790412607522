import axios from 'axios'
import setAuthToken from '../utils/SetAuthToken'
import { ReduxStore as redux_store } from '../ReduxStore'
import { USER_LOADING, SET_CURRENT_USER } from './types'

axios.defaults.withCredentials = true

// Register User
export const registerUser = async (userData) => {
    return axios
        .post('/api/users/register', userData)
        .then((res) => {
            alert(
                'Registered! Please check your email and enter the verification code in the next screen to activate your account.'
            )
            return 'success'
        })
        .catch((err) => {
            handleError(err)
            return 'error'
        })
}

// Login - get user token and set logged-in user
export const loginUser = async (userData) => {
    return axios
        .post('/api/users/login', userData)
        .then((res) => {
            //alert(`res is: ${JSON.stringify(res)}`)
            const response = res.data
            saveDataToStorage('user', response)
            redux_store.dispatch(setCurrentUser(response.user_info))
            return 'success'
        })
        .catch((err) => {
            handleError(err)
            alert(`Error: ${JSON.stringify(err)}`)
            return 'error'
        })
}

// Save data to localStorage (replacing AsyncStorage)
const saveDataToStorage = (name, data) => {
    try {
        localStorage.setItem(name, JSON.stringify(data))
    } catch (err) {
        console.error('Error saving data to localStorage: ', err)
    }
}

// Log user out
export const logoutUser = async () => {
    localStorage.removeItem('user')
    setAuthToken(false)
    redux_store.dispatch(setCurrentUser({}))

    return axios
        .get('/api/users/logout')
        .then(() => {
            return 'success'
        })
        .catch((err) => {
            handleError(err)
            return 'error'
        })
}

// Confirm Registration
export const confirmRegistration = async (verificationCode) => {
    return axios
        .get(`/api/users/confirmation?token=${verificationCode}`)
        .then(() => {
            alert('Successfully Registered. Continue logging in.')
            return 'success'
        })
        .catch((err) => {
            handleError(err)
            return 'error'
        })
}

// Resend Email Verification
export const resendEmailVerification = async (userData) => {
    return axios
        .post('/api/users/resendemailverification', userData)
        .then(() => {
            alert(
                'Resent Email Verification Link! Please check your email to get the verification code to activate your account.'
            )
            return 'success'
        })
        .catch((err) => {
            handleError(err)
            return 'error'
        })
}

// Send Reset Password Email
export const sendResetPasswordEmail = async (userData) => {
    return axios
        .post('/api/users/sendresetpasswordemail', userData)
        .then(() => {
            alert(
                'Password Reset Email Sent! Please check your email and get the verification code to enter in the next screen.'
            )
            return 'success'
        })
        .catch((err) => {
            handleError(err)
            return 'error'
        })
}

// Send Expo Push Token
export const sendExpoPushToken = async (userData) => {
    return axios
        .post('/api/users/sendExpoToken', userData)
        .then(() => {
            return 'success'
        })
        .catch((err) => {
            alert('Failed sending Expo token with error: ', err)
            return 'error'
        })
}

// Set User Country
export const setUserLocation = async (countryCode, timezone) => {
    return axios
        .get(
            `/api/users/setUserCountry?countryCode=${countryCode}&timezone=${timezone}`
        )
        .then(() => {
            return 'success'
        })
        .catch((err) => {
            alert('Failed setting user country code with error: ', err)
            return 'error'
        })
}

// Reset Password
export const resetPassword = async (userData) => {
    return axios
        .post('/api/users/resetpassword', userData)
        .then(() => {
            alert(
                'Password successfully reset! Use your new login credentials to login.'
            )
            return 'success'
        })
        .catch((err) => {
            handleError(err)
            return 'error'
        })
}

// Set current user
export const setCurrentUser = (decoded) => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded,
    }
}

// User loading
export const setUserLoading = () => {
    return {
        type: USER_LOADING,
    }
}

// Utility function for handling errors
const handleError = (err) => {
    if (err.response) {
        console.error(err.response.data)
        alert(`Error: ${err.response.data.msg || err.response.data}`)
    } else if (err.request) {
        console.error('No response received, request is: ', err.request)
    } else {
        console.error('Error', err.message)
    }
}
