import React, { useEffect } from 'react'
import { Link, Route, Routes, useNavigate, useLocation } from 'react-router-dom' // React Router
import { Home, Chat, Settings } from '@mui/icons-material' // Material-UI Icons
import { themeColors } from '../theme'
import { DashboardScreen } from '../screens/DashboardScreen'
import { SelectAvatarGridScreen } from '../screens/SelectAvatarGridScreen'
import { CreateAvatarScreen } from '../screens/CreateAvatarScreen'
import { ChatScreen } from '../screens/ChatScreen'
import { SettingsScreen } from '../screens/SettingsScreen'
import { TermsAndConditionScreen } from '../screens/TermsAndConditionScreen'
import { AboutScreen } from '../screens/AboutScreen'
import { UpgradeSubscriptionScreen } from '../screens/UpgradeSubscriptionScreen'
import { RazorpaySubscriptionScreen } from '../screens/RazorpaySubscriptionScreen'
import { getAvatars, getCustomAvatars } from '../actions/ChatActions'
import { setUserLocation } from '../actions/AuthActions'
import { getUserCountry } from '../actions/UtilActions'
import { useSelector } from 'react-redux'
import { useSwipeable } from 'react-swipeable' // Import swipeable

export default function TabNavigation() {
    const navigate = useNavigate()
    const location = useLocation() // Hook to get current path
    const tabs = ['/dashboard', '/chat', '/settings'] // Array for tab paths
    const currentIndex = tabs.indexOf(window.location.pathname) // Get the current tab index

    useEffect(() => {
        const setUp = async () => {
            await getAvatars()
            await getCustomAvatars()
            const { countryCode, timezone } = await getUserCountry()
            await setUserLocation(countryCode, timezone)
        }

        setUp()
    }, [])

    // Function to handle swipe gestures
    const handleSwipe = (direction) => {
        if (direction === 'left') {
            const nextIndex =
                currentIndex + 1 < tabs.length ? currentIndex + 1 : 0
            navigate(tabs[nextIndex])
        } else if (direction === 'right') {
            const prevIndex =
                currentIndex - 1 >= 0 ? currentIndex - 1 : tabs.length - 1
            navigate(tabs[prevIndex])
        }
    }

    // Swipeable handlers
    const handlers = useSwipeable({
        onSwipedLeft: () => handleSwipe('left'),
        onSwipedRight: () => handleSwipe('right'),
        preventScrollOnSwipe: true, // Prevent default scrolling behavior
        trackMouse: true, // Optional: Allows swipe with mouse (for testing on desktop)
    })

    // Function to determine if a tab is active
    const isActive = (path) => location.pathname === path

    return (
        <div style={{ paddingBottom: '0px' }} {...handlers}>
            {' '}
            {/* Add swipe handlers here */}{' '}
            {/* Adjust bottom padding to avoid content overlap */}
            {/* Routes for different screens */}
            <Routes>
                <Route path="/login" element={<DashboardScreen />} />
                <Route path="/" element={<DashboardScreen />} />
                <Route path="/dashboard" element={<DashboardScreen />} />
                <Route
                    path="/select-avatar"
                    element={<SelectAvatarGridScreen />}
                />
                <Route path="/create-avatar" element={<CreateAvatarScreen />} />
                <Route path="/chat" element={<ChatScreen />} />

                <Route path="/settings" element={<SettingsScreen />} />
                <Route path="/about" element={<AboutScreen />} />
                <Route
                    path="/terms-conditions"
                    element={<TermsAndConditionScreen />}
                />
                <Route
                    path="/upgrade-subscription"
                    element={<UpgradeSubscriptionScreen />}
                />
                <Route
                    path="/razorpay-subscription"
                    element={<RazorpaySubscriptionScreen />}
                />
                {/*
                <Route path="/upgrade" element={<UpgradeScreen />} />
                <Route path="/payment-gateway" element={<WebviewScreen />} />
                <Route path="/credits" element={<CreditsScreen />} />
                */}
            </Routes>
            {/* Tab bar at the bottom */}
            <nav
                style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    padding: '10px',
                    backgroundColor: themeColors.redcolor,
                    position: 'fixed', // Fixed positioning
                    bottom: '0', // Align at the bottom
                    left: '0',
                    width: '100%', // Make it full width
                    zIndex: 1000, // Ensure it's on top of other elements
                }}
            >
                <Link
                    to="/dashboard"
                    style={{
                        textDecoration: 'none',
                        color: isActive('/dashboard')
                            ? themeColors.darkVioletColor
                            : 'white', // Highlight active tab
                        textAlign: 'center',
                    }}
                >
                    <Home
                        style={{
                            color: isActive('/dashboard')
                                ? themeColors.darkVioletColor
                                : 'white', // Highlight active tab
                            fontSize: '24px',
                        }}
                    />
                </Link>
                <Link
                    to="/chat"
                    style={{
                        textDecoration: 'none',
                        color: isActive('/chat')
                            ? themeColors.darkVioletColor
                            : 'white', // Highlight active tab
                        textAlign: 'center',
                    }}
                >
                    <Chat
                        style={{
                            color: isActive('/chat')
                                ? themeColors.darkVioletColor
                                : 'white', // Highlight active tab
                            fontSize: '24px',
                        }}
                    />
                </Link>
                <Link
                    to="/settings"
                    style={{
                        textDecoration: 'none',
                        color: isActive('/settings')
                            ? themeColors.darkVioletColor
                            : 'white', // Highlight active tab
                        textAlign: 'center',
                    }}
                >
                    <Settings
                        style={{
                            color: isActive('/settings')
                                ? themeColors.darkVioletColor
                                : 'white', // Highlight active tab
                            fontSize: '24px',
                        }}
                    />
                </Link>
            </nav>
        </div>
    )
}
